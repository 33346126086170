import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Dropzone from "react-dropzone";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CircularProgress from "@mui/material/CircularProgress";
import Skeleton from "@mui/material/Skeleton";
import Hidden from "@mui/material/Hidden";
import LoadingButton from "@mui/lab/LoadingButton";
import Badge from "@mui/material/Badge";
import Tooltip from "@mui/material/Tooltip";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Alert from "@mui/material/Alert";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import UserIcon from "@mui/icons-material/Person";
import LockIcon from "@mui/icons-material/Lock";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityIconOff from "@mui/icons-material/VisibilityOff";
import InputCustomFields from "../inputs/input-custom-fields";
import InputTextArea from "../inputs/input-textarea";
import { useAuth } from "../../../services/auth";
import { useTenancy } from "../../../services/tenancy";
import { useAlert } from "../../../services/alert";
import { useApiAuth } from "../../../api/api-auth";
import { useApiCentral } from "../../../api/api-central";
import { useApiTenant } from "../../../api/api-tenant";
import { cleanText } from "../../../helpers/clean";
import InputSwitch from "../inputs/input-switch";
export default function UserForm({ type, status, user }) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const auth = useAuth();
    const tenancy = useTenancy();
    const alert = useAlert();
    const apiAuth = useApiAuth();
    const apiTenant = useApiTenant();
    const apiCentral = useApiCentral();
    const [isActive, setIsActive] = useState((_a = user === null || user === void 0 ? void 0 : user.is_enabled) !== null && _a !== void 0 ? _a : true);
    const [firstName, setFirstName] = useState((_b = user === null || user === void 0 ? void 0 : user.first_name) !== null && _b !== void 0 ? _b : "");
    const [lastName, setLastName] = useState((_c = user === null || user === void 0 ? void 0 : user.last_name) !== null && _c !== void 0 ? _c : "");
    const [email, setEmail] = useState((_d = user === null || user === void 0 ? void 0 : user.email) !== null && _d !== void 0 ? _d : "");
    const [username, setUsername] = useState((_e = user === null || user === void 0 ? void 0 : user.username) !== null && _e !== void 0 ? _e : "");
    const [phone] = useState((_f = user === null || user === void 0 ? void 0 : user.phone) !== null && _f !== void 0 ? _f : "");
    const [role, setRole] = useState((user === null || user === void 0 ? void 0 : user.roles[0])
        ? user.roles[0].name
            ? user.roles[0].name
            : type === "client"
                ? "agent"
                : "user"
        : type === "client"
            ? "agent"
            : "user");
    const [avatar, setAvatar] = useState((_g = user === null || user === void 0 ? void 0 : user.avatar_url) !== null && _g !== void 0 ? _g : "");
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [customFields, setCustomFields] = useState((_h = user === null || user === void 0 ? void 0 : user.custom_fields) !== null && _h !== void 0 ? _h : []);
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [newAvatar, setNewAvatar] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errors, setErrors] = useState(null);
    const [selectRoles] = useState(type === "client" ? ["agent"] : ["super_admin", "user"]);
    const [disabledCustomFields, setDisabledCustomFields] = useState([]);
    const [passwordError, setPasswordError] = useState(false);
    const [isLoadingCustomFields, setIsLoadingCustomFields] = useState(true);
    const [tenancyCustomFields, setTenancyCustomFields] = useState([]);
    const [is2faEnabled, setIs2faEnabled] = useState(auth.user ? auth.user["2fa"] : false);
    const [isAuthenticationOpened, setIsAuthenticationOpened] = useState(false);
    const [authenticationPassword, setAuthenticationPassword] = useState("");
    const [showAuthenticationPassword, setShowAuthenticationPassword] = useState(false);
    const [isAuthenticationLoading, setIsAuthenticationLoading] = useState(false);
    const [isAuthenticationPasswordNeeded, setIsAuthenticationPasswordNeeded] = useState(false);
    const [qrCode, setQrCode] = useState(null);
    const [backupCodes, setBackupCodes] = useState("");
    const [authenticationCode, setAuthenticationCode] = useState("");
    const [disabled2fa, setDisabled2fa] = useState(false);
    // Because of security reasons
    const isEditDisabled = (type === "client" && status === "edit") || (user && user.is_sso);
    const createUser = () => {
        setIsSaving(true);
        setIsError(false);
        const api = type === "client" ? apiTenant : apiCentral;
        api.CREATE_user(cleanText(firstName), cleanText(lastName), email.toLowerCase(), username, isActive, role, isActive ? newPassword : undefined, cleanText(phone), newAvatar, customFields.sort((a, b) => a.index - b.index))
            .then(response => {
            setIsSaving(false);
            alert.show(t("users.user-form.user-created"), "success");
            navigate(type == "client"
                ? tenancy.link("/settings/users/" + response.data.tenant_user.id)
                : "/administration/users/" + response.data.central_user.id);
        })
            .catch(error => {
            setIsSaving(false);
            setIsError(true);
            setErrors(error.response.data.errors);
            console.error(error.response);
            alert.show(t("users.user-form.creating-error-occured"), "error");
        });
    };
    const updateUser = () => {
        if (!user)
            return;
        setIsSaving(true);
        setIsError(false);
        const api = type === "client" ? apiTenant : apiCentral;
        api.UPDATE_user(user === null || user === void 0 ? void 0 : user.id, cleanText(firstName), cleanText(lastName), email.toLowerCase(), username, isActive, role, isActive ? oldPassword : undefined, isActive ? newPassword : undefined, cleanText(phone), newAvatar, 
        // @ts-ignore
        customFields.sort((a, b) => (a.custom_field ? a.custom_field.index : a.index) -
            (b.custom_field ? b.custom_field.index : b.index)), undefined, undefined, disabled2fa)
            .then(() => {
            var _a;
            setIsSaving(false);
            if (status == "account") {
                auth.currentUser(false);
                navigate(tenancy.link("/account"));
                setTimeout(() => {
                    alert.show(t("users.user-form.account-updated"), "success");
                }, 1000);
            }
            else {
                alert.show(t("users.user-form.user-updated"), "success");
                navigate(type == "client"
                    ? tenancy.link("/settings/users/" + (user === null || user === void 0 ? void 0 : user.id))
                    : "/administration/users/" + (user === null || user === void 0 ? void 0 : user.id));
                if (user.global_id === ((_a = auth.user) === null || _a === void 0 ? void 0 : _a.global_id)) {
                    setTimeout(() => {
                        auth.currentUser(false);
                    }, 1000);
                }
            }
        })
            .catch(error => {
            setIsSaving(false);
            setIsError(true);
            setErrors(error.response.data.errors);
            console.error(error.response);
            if (status == "account") {
                alert.show(t("users.user-form.updating-account-error-occured"), "error");
            }
            else {
                alert.show(t("users.user-form.updating-user-error-occured"), "error");
            }
        });
    };
    const handleAuthenticationPassword = () => {
        if (!user)
            return;
        setIsAuthenticationLoading(true);
        apiAuth
            .POST_2faConfirmPassword(user.email || user.username, authenticationPassword)
            .then(() => {
            if (isAuthenticationPasswordNeeded) {
                handleAuthenticationRecoveryCodes();
                return;
            }
            apiAuth
                .CREATE_enable2fa()
                .then(() => {
                apiAuth
                    .GET_2faQrCode()
                    .then(response => {
                    setIsAuthenticationLoading(false);
                    setIsAuthenticationOpened(false);
                    setQrCode(response.data.svg);
                })
                    .catch(error => {
                    setIsAuthenticationLoading(false);
                    console.error(error.response);
                    alert.show(t("2fa.enable-error"), "error");
                });
            })
                .catch(error => {
                setIsAuthenticationLoading(false);
                console.error(error.response);
                alert.show(t("2fa.enable-error"), "error");
            });
        })
            .catch(error => {
            setIsAuthenticationLoading(false);
            console.error(error.response);
            alert.show(t("2fa.wrong-password"), "error");
        });
    };
    const handleAuthenticationClose = () => {
        setIsAuthenticationOpened(false);
        setIsAuthenticationLoading(false);
        setAuthenticationPassword("");
    };
    const handleAuthenticationConfirm = () => {
        setIsAuthenticationLoading(true);
        apiAuth
            .CREATE_confirm2fa(authenticationCode)
            .then(() => {
            apiAuth
                .GET_2faRecoveryCodes()
                .then(response => {
                setQrCode(null);
                setBackupCodes(response.data.join("\r\n"));
                setIs2faEnabled(true);
            })
                .catch(error => {
                console.error(error.response);
                alert.show(t("2fa.enable-error"), "error");
            });
        })
            .catch(error => {
            console.error(error.response);
            alert.show(t("2fa.enable-error"), "error");
        })
            .finally(() => {
            setIsAuthenticationLoading(false);
        });
    };
    const handleAuthenticationRegenerate = () => {
        setIsAuthenticationLoading(true);
        apiAuth
            .POST_2faRecoveryCodes()
            .then(() => {
            apiAuth
                .GET_2faRecoveryCodes()
                .then(response => {
                setBackupCodes(response.data.join("\r\n"));
            })
                .catch(error => {
                console.error(error.response);
            })
                .finally(() => {
                setIsAuthenticationLoading(false);
            });
        })
            .catch(error => {
            console.error(error.response);
            setIsAuthenticationLoading(false);
        });
    };
    const handleAuthenticationDisable = () => {
        if (process.env.ENFORCE_2FA === "true")
            return;
        setIsAuthenticationLoading(true);
        apiAuth
            .DELETE_disable2fa()
            .then(() => {
            setQrCode(null);
            setBackupCodes("");
            setIs2faEnabled(false);
        })
            .catch(error => {
            console.error(error.response);
        })
            .finally(() => {
            setIsAuthenticationLoading(false);
        });
    };
    const handleAuthenticationRecoveryCodes = () => {
        apiAuth
            .GET_2faRecoveryCodes()
            .then(response => {
            setBackupCodes(response.data.join("\r\n"));
            setIsAuthenticationLoading(false);
            setIsAuthenticationOpened(false);
            setIsAuthenticationPasswordNeeded(false);
        })
            .catch(error => {
            console.error(error.response);
        });
    };
    const validatePassword = (password) => {
        if (/[a-z]/.test(password) &&
            /[A-Z]/.test(password) &&
            /[0-9]/.test(password) &&
            /[\W_]/.test(password) &&
            password.length >= 12) {
            setPasswordError(false);
        }
        else {
            setPasswordError(true);
        }
    };
    useEffect(() => {
        if (type !== "client") {
            setIsLoadingCustomFields(false);
            return;
        }
        apiTenant
            .GET_customFields("tenant_users", 10000, 1, "index")
            .then(response => {
            setTenancyCustomFields(response.data.custom_fields);
            setIsLoadingCustomFields(false);
        })
            .catch(error => {
            if (!apiTenant.isCancel(error)) {
                console.error(error);
                setIsLoadingCustomFields(false);
            }
        });
        return () => apiTenant.cancel();
    }, []);
    useEffect(() => {
        if (!is2faEnabled)
            return;
        apiAuth
            .GET_2faConfirmedPassword()
            .then(response => {
            if (response.data.confirmed) {
                handleAuthenticationRecoveryCodes();
            }
            else {
                setIsAuthenticationPasswordNeeded(true);
            }
        })
            .catch(error => {
            console.error(error.response);
            setIsAuthenticationPasswordNeeded(true);
        });
    }, []);
    return (_jsxs(Fragment, { children: [_jsxs(Box, Object.assign({ component: "form" }, { children: [_jsx(Box, Object.assign({ sx: {
                            marginBottom: 2,
                        } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, md: 4 }, { children: _jsxs(Box, Object.assign({ sx: {
                                            display: "flex",
                                        } }, { children: [_jsx(Box, Object.assign({ sx: {
                                                    marginRight: 2,
                                                } }, { children: _jsx(UserIcon, { fontSize: "large" }) })), _jsxs(Box, { children: [_jsx(Typography, Object.assign({ variant: "h5" }, { children: t("details") })), _jsx(Typography, Object.assign({ color: "textSecondary" }, { children: status == "account"
                                                            ? t("users.profile")
                                                            : t("users.user-form.user-details") }))] })] })) })), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 8 }, { children: _jsx(Paper, { children: _jsx(Box, Object.assign({ sx: {
                                                padding: 4,
                                            } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [type === "central" && (_jsxs(Fragment, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(InputSwitch, { label: t("users.user-attributes.active"), required: false, disabled: isEditDisabled, checked: isActive, setChecked: () => setIsActive(!isActive) }) })), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Divider, { light: true }) }))] })), _jsxs(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: [_jsxs(FormControl, Object.assign({ required: true, fullWidth: true, disabled: isEditDisabled, error: isError
                                                                    ? errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("first_name")
                                                                    : false }, { children: [_jsx(InputLabel, { children: t("users.user-attributes.first-name") }), _jsx(OutlinedInput, { label: t("users.user-attributes.first-name") + " *", value: firstName, onChange: event => {
                                                                            setFirstName(event.target.value);
                                                                            if (isError &&
                                                                                (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("first_name")))
                                                                                setIsError(false);
                                                                        } })] })), isError && (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("first_name")) && (_jsx(FormHelperText, Object.assign({ error: true }, { children: errors["first_name"][0] })))] })), _jsxs(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: [_jsxs(FormControl, Object.assign({ required: true, fullWidth: true, disabled: isEditDisabled, error: isError
                                                                    ? errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("last_name")
                                                                    : false }, { children: [_jsx(InputLabel, { children: t("users.user-attributes.last-name") }), _jsx(OutlinedInput, { label: t("users.user-attributes.last-name") + "*", value: lastName, onChange: event => {
                                                                            setLastName(event.target.value);
                                                                            if (isError &&
                                                                                (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("last_name")))
                                                                                setIsError(false);
                                                                        } })] })), isError && (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("last_name")) && (_jsx(FormHelperText, Object.assign({ error: true }, { children: errors["last_name"][0] })))] })), _jsxs(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: [_jsxs(FormControl, Object.assign({ required: !username, fullWidth: true, disabled: isEditDisabled, error: isError
                                                                    ? errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("email")
                                                                    : false }, { children: [_jsx(InputLabel, { children: t("users.user-attributes.email") }), _jsx(OutlinedInput, { type: "email", label: `${t("users.user-attributes.email")} ${username ? "" : "*"}`, value: email, onChange: event => {
                                                                            let newEmail = event.target.value;
                                                                            newEmail = newEmail.toLowerCase();
                                                                            newEmail = newEmail.replace(/č/g, "c");
                                                                            newEmail = newEmail.replace(/ć/g, "c");
                                                                            newEmail = newEmail.replace(/š/g, "s");
                                                                            newEmail = newEmail.replace(/ž/g, "z");
                                                                            newEmail = newEmail.replace(/đ/g, "d");
                                                                            setEmail(newEmail);
                                                                            if (isError &&
                                                                                (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("email")))
                                                                                setIsError(false);
                                                                        } })] })), isError && (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("email")) && (_jsx(FormHelperText, Object.assign({ error: true }, { children: errors["email"][0] })))] })), _jsxs(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: [_jsxs(FormControl, Object.assign({ required: !email, fullWidth: true, disabled: isEditDisabled, error: isError
                                                                    ? errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("username")
                                                                    : false }, { children: [_jsx(InputLabel, { children: t("users.user-attributes.username") }), _jsx(OutlinedInput, { label: `${t("users.user-attributes.username")} ${email ? "" : "*"}`, value: username, onChange: event => {
                                                                            setUsername(event.target.value);
                                                                            if (isError &&
                                                                                (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("username")))
                                                                                setIsError(false);
                                                                        } })] })), isError && (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("username")) && (_jsx(FormHelperText, Object.assign({ error: true }, { children: errors["username"][0] })))] })), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Divider, { light: true }) })), isActive && (_jsxs(Fragment, { children: [status === "account" && (_jsxs(Grid, Object.assign({ item: true, xs: 12, sm: 4 }, { children: [_jsxs(FormControl, Object.assign({ fullWidth: true, error: isError
                                                                            ? errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("old_password")
                                                                            : false }, { children: [_jsx(InputLabel, { children: oldPassword ||
                                                                                    newPassword ||
                                                                                    confirmPassword
                                                                                    ? t("users.user-attributes.old-password") + "*"
                                                                                    : t("users.user-attributes.old-password") }), _jsx(OutlinedInput, { type: showOldPassword
                                                                                    ? "text"
                                                                                    : "password", label: oldPassword ||
                                                                                    newPassword ||
                                                                                    confirmPassword
                                                                                    ? t("users.user-attributes.old-password") + "*"
                                                                                    : t("users.user-attributes.old-password"), value: oldPassword, onChange: event => {
                                                                                    setOldPassword(event.target.value);
                                                                                    if (isError &&
                                                                                        (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("old_password")))
                                                                                        setIsError(false);
                                                                                }, endAdornment: _jsx(InputAdornment, Object.assign({ position: "end" }, { children: _jsx(IconButton, Object.assign({ onClick: () => setShowOldPassword(!showOldPassword), edge: "end", size: "large" }, { children: showOldPassword ? (_jsx(VisibilityIcon, {})) : (_jsx(VisibilityIconOff, {})) })) })) })] })), isError &&
                                                                        (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("old_password")) && (_jsx(FormHelperText, Object.assign({ error: true }, { children: errors["old_password"][0] })))] }))), _jsxs(Grid, Object.assign({ item: true, xs: 12, sm: status == "account" ? 4 : 6 }, { children: [_jsxs(FormControl, Object.assign({ fullWidth: true, disabled: isEditDisabled, error: isError
                                                                            ? errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("password")
                                                                            : false }, { children: [_jsx(InputLabel, { children: status === "account"
                                                                                    ? oldPassword ||
                                                                                        newPassword ||
                                                                                        confirmPassword
                                                                                        ? t("users.user-attributes.new-password") + "*"
                                                                                        : t("users.user-attributes.new-password")
                                                                                    : status === "edit"
                                                                                        ? t("users.user-attributes.new-password")
                                                                                        : t("users.user-attributes.password") + " *" }), _jsx(OutlinedInput, { type: showNewPassword
                                                                                    ? "text"
                                                                                    : "password", label: status === "account"
                                                                                    ? oldPassword ||
                                                                                        newPassword ||
                                                                                        confirmPassword
                                                                                        ? t("users.user-attributes.new-password") + "*"
                                                                                        : t("users.user-attributes.new-password")
                                                                                    : status === "edit"
                                                                                        ? t("users.user-attributes.new-password")
                                                                                        : t("users.user-attributes.password") + " *", value: newPassword, onChange: event => {
                                                                                    setNewPassword(event.target.value);
                                                                                    validatePassword(event.target.value);
                                                                                    if (isError &&
                                                                                        (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("password")))
                                                                                        setIsError(false);
                                                                                }, endAdornment: _jsx(InputAdornment, Object.assign({ position: "end" }, { children: _jsx(IconButton, Object.assign({ onClick: () => setShowNewPassword(!showNewPassword), edge: "end", size: "large" }, { children: showNewPassword ? (_jsx(VisibilityIcon, {})) : (_jsx(VisibilityIconOff, {})) })) })) })] })), passwordError && (_jsx(FormHelperText, Object.assign({ error: true }, { children: t("users.user-form.password-validation-message") }))), isError &&
                                                                        (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("password")) && (_jsx(FormHelperText, Object.assign({ error: true }, { children: errors["password"][0] })))] })), _jsx(Grid, Object.assign({ item: true, xs: 12, sm: status == "account" ? 4 : 6 }, { children: _jsxs(FormControl, Object.assign({ fullWidth: true, disabled: isEditDisabled }, { children: [_jsx(InputLabel, { children: status === "account"
                                                                                ? oldPassword ||
                                                                                    newPassword ||
                                                                                    confirmPassword
                                                                                    ? t("users.user-attributes.confirm-password") + "*"
                                                                                    : t("users.user-attributes.confirm-password")
                                                                                : status === "edit"
                                                                                    ? t("users.user-attributes.confirm-password")
                                                                                    : t("users.user-attributes.confirm-password") + " *" }), _jsx(OutlinedInput, { type: showConfirmPassword
                                                                                ? "text"
                                                                                : "password", label: status === "account"
                                                                                ? oldPassword ||
                                                                                    newPassword ||
                                                                                    confirmPassword
                                                                                    ? t("users.user-attributes.confirm-password") + "*"
                                                                                    : t("users.user-attributes.confirm-password")
                                                                                : status === "edit"
                                                                                    ? t("users.user-attributes.confirm-password")
                                                                                    : t("users.user-attributes.confirm-password") + " *", value: confirmPassword, onChange: event => {
                                                                                setConfirmPassword(event.target.value);
                                                                            }, endAdornment: _jsx(InputAdornment, Object.assign({ position: "end" }, { children: _jsx(IconButton, Object.assign({ onClick: () => setShowConfirmPassword(!showConfirmPassword), edge: "end", size: "large" }, { children: showConfirmPassword ? (_jsx(VisibilityIcon, {})) : (_jsx(VisibilityIconOff, {})) })) })) }), confirmPassword !== newPassword && (_jsx(FormHelperText, Object.assign({ error: true }, { children: t("users.user-form.passwords-dont-match") })))] })) })), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Divider, { light: true }) }))] })), status === "account" ? (_jsx(Grid, Object.assign({ item: true, xs: 12, sm: 3 }, { children: _jsxs(Badge, Object.assign({ sx: {
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                width: "100%",
                                                                color: "rgb(244, 67, 54)",
                                                            }, invisible: avatar === "" && newAvatar === null, badgeContent: _jsx(Box, Object.assign({ sx: {
                                                                    paddingBottom: 0.5,
                                                                } }, { children: _jsx(Tooltip
                                                                // @ts-ignore
                                                                , Object.assign({ 
                                                                    // @ts-ignore
                                                                    title: t("users.user-form.remove-avatar"), placement: "top", arrow: true }, { children: _jsx(IconButton, Object.assign({ style: {
                                                                            color: "rgb(244, 67, 54)",
                                                                        }, onClick: () => {
                                                                            if (avatar) {
                                                                                setAvatar("");
                                                                                setNewAvatar(null);
                                                                            }
                                                                        }, size: "large" }, { children: _jsx(RemoveCircleIcon, {}) })) })) })) }, { children: [_jsx(FormControl, Object.assign({ fullWidth: true, error: isError
                                                                        ? errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("avatar")
                                                                        : false }, { children: _jsx(Dropzone, Object.assign({ maxFiles: 1, maxSize: 50000000, onDrop: files => {
                                                                            setNewAvatar(files[0]);
                                                                            if (isError &&
                                                                                (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("avatar")))
                                                                                setIsError(false);
                                                                        } }, { children: ({ getRootProps, getInputProps }) => (_jsx(Card, Object.assign({ variant: "outlined" }, getRootProps(), { children: _jsx(CardActionArea, { children: _jsxs(Box, Object.assign({ sx: {
                                                                                        height: "100px",
                                                                                        display: "flex",
                                                                                        justifyContent: "center",
                                                                                        alignItems: "center",
                                                                                    } }, { children: [newAvatar ? (_jsx("img", { src: URL.createObjectURL(newAvatar), width: "60px", height: "60px", alt: "Avatar" })) : avatar ? (_jsx("img", { src: avatar, width: "60px", height: "60px", alt: "Avatar" })) : (_jsx(Skeleton, { variant: "circular", width: "60px", height: "60px" })), _jsx("input", Object.assign({}, getInputProps({
                                                                                            accept: "image/*,image/svg+xml",
                                                                                        })))] })) }) }))) })) })), isError && (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("avatar")) ? (_jsx(FormHelperText, Object.assign({ error: true }, { children: errors["avatar"][0] }))) : (_jsx(FormHelperText, { children: t("users.user-form.drag&drop-avatar") }))] })) }))) : (_jsxs(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: [_jsxs(FormControl, Object.assign({ required: true, fullWidth: true, disabled: isEditDisabled, error: isError
                                                                    ? errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("role")
                                                                    : false }, { children: [_jsx(InputLabel, { children: t("users.role") }), _jsx(Select, Object.assign({ label: t("users.role") + "*", value: role, onChange: event => {
                                                                            setRole(event.target.value);
                                                                            if (isError &&
                                                                                (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("role")))
                                                                                setIsError(false);
                                                                        } }, { children: selectRoles.map(selectRole => {
                                                                            return (_jsx(MenuItem, Object.assign({ value: selectRole }, { children: selectRole === "admin"
                                                                                    ? t("users.roles.admin")
                                                                                    : selectRole === "manager"
                                                                                        ? t("users.roles.manager")
                                                                                        : selectRole === "agent"
                                                                                            ? t("users.roles.agent")
                                                                                            : selectRole ===
                                                                                                "super_admin"
                                                                                                ? t("users.roles.super-admin")
                                                                                                : t("users.roles.user") }), selectRole));
                                                                        }) }))] })), isError && (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("role")) && (_jsx(FormHelperText, Object.assign({ error: true }, { children: errors["role"][0] })))] }))), type === "central" && status === "edit" && user && (_jsxs(Fragment, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Divider, { light: true }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: _jsx(InputSwitch, { label: t("2fa.reset"), disabled: !user["2fa"], required: false, checked: disabled2fa, setChecked: () => setDisabled2fa(!disabled2fa) }) }))] }))] })) })) }) }))] })) })), isLoadingCustomFields ? (_jsx(Fragment, { children: _jsx(Box, Object.assign({ sx: {
                                display: "flex",
                                justifyContent: "center",
                                marginY: 3,
                                paddingY: 3,
                            } }, { children: _jsx(CircularProgress, { color: "inherit", size: 30 }) })) })) : (_jsxs(Fragment, { children: [type === "client" && tenancyCustomFields.length > 0 && (_jsx(InputCustomFields, { type: "tenant_users", tenancyCustomFields: tenancyCustomFields, customFields: customFields, setCustomFields: setCustomFields, disabledCustomFields: disabledCustomFields, setDisabledCustomFields: setDisabledCustomFields, error: "custom_fields.", errors: errors, isError: isError, setIsError: setIsError })), _jsx(Box, Object.assign({ sx: {
                                    marginBottom: 6,
                                } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsx(Hidden, Object.assign({ mdDown: true }, { children: _jsx(Grid, { item: true, xs: 12, md: 4 }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 8 }, { children: _jsx(Box, Object.assign({ sx: {
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                    marginTop: 2,
                                                } }, { children: _jsx(LoadingButton, Object.assign({ type: "button", variant: "contained", color: "secondary", loading: isSaving, disabled: isEditDisabled ||
                                                        (status == "account"
                                                            ? newPassword && !oldPassword
                                                            : false) ||
                                                        confirmPassword !== newPassword ||
                                                        !firstName ||
                                                        (!email && !username) ||
                                                        !lastName ||
                                                        passwordError ||
                                                        (status == "new" && isActive ? !newPassword : false), onClick: () => {
                                                        status == "new" ? createUser() : updateUser();
                                                    }, loadingIndicator: _jsx(CircularProgress, { color: "inherit", size: 16 }) }, { children: status == "new"
                                                        ? t("form-component.create")
                                                        : t("form-component.update") })) })) }))] })) }))] })), status == "account" && (_jsx(Box, Object.assign({ sx: {
                            marginBottom: 6,
                        } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, md: 4 }, { children: _jsxs(Box, Object.assign({ sx: {
                                            display: "flex",
                                        } }, { children: [_jsx(Box, Object.assign({ sx: {
                                                    marginRight: 2,
                                                } }, { children: _jsx(LockIcon, { fontSize: "large" }) })), _jsxs(Box, { children: [_jsx(Typography, Object.assign({ variant: "h5" }, { children: "2FA" })), _jsx(Typography, Object.assign({ color: "textSecondary" }, { children: t("2fa.name") }))] })] })) })), !user ||
                                    (user && !user.is_sso && (_jsx(Grid, Object.assign({ item: true, xs: 12, md: 8 }, { children: _jsx(Paper, { children: _jsx(Box, Object.assign({ sx: {
                                                    padding: 4,
                                                } }, { children: _jsx(Grid, Object.assign({ container: true, spacing: 4 }, { children: _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: is2faEnabled ? (_jsxs(Fragment, { children: [_jsx(Alert, Object.assign({ severity: "success" }, { children: t("2fa.enabled") })), isAuthenticationPasswordNeeded ? (_jsx(Fragment, { children: _jsx(Button, Object.assign({ color: "primary", variant: "contained", size: "small", disableElevation: true, onClick: () => setIsAuthenticationOpened(true), sx: {
                                                                            marginY: 2,
                                                                        } }, { children: t("2fa.manage") })) })) : (_jsxs(Fragment, { children: [_jsx(Typography, Object.assign({ variant: "body2", color: "textSecondary", sx: {
                                                                                marginY: 2,
                                                                            } }, { children: t("2fa.recover-codes") })), backupCodes && (_jsx(InputTextArea, { label: "", value: backupCodes, setValue: () => { }, required: false, disabled: true })), _jsxs(Box, Object.assign({ sx: {
                                                                                display: "flex",
                                                                                gap: 2,
                                                                                marginTop: 2,
                                                                            } }, { children: [_jsx(LoadingButton, Object.assign({ type: "button", variant: "contained", color: "warning", size: "small", disableElevation: true, loading: isAuthenticationLoading, onClick: handleAuthenticationRegenerate, loadingIndicator: _jsx(CircularProgress, { color: "inherit", size: 16 }) }, { children: t("2fa.regenerate-codes") })), _jsx(LoadingButton, Object.assign({ type: "button", variant: "contained", color: "error", size: "small", disableElevation: true, disabled: process.env
                                                                                        .ENFORCE_2FA ===
                                                                                        "true", loading: isAuthenticationLoading, onClick: handleAuthenticationDisable, loadingIndicator: _jsx(CircularProgress, { color: "inherit", size: 16 }) }, { children: t("2fa.disable") }))] }))] }))] })) : qrCode ? (_jsxs(Fragment, { children: [_jsx(Alert, Object.assign({ severity: "info" }, { children: t("2fa.finish") })), _jsx(Typography, Object.assign({ variant: "body2", color: "textSecondary", sx: {
                                                                        marginY: 2,
                                                                    } }, { children: t("2fa.scan") })), _jsx("div", { dangerouslySetInnerHTML: {
                                                                        __html: qrCode,
                                                                    } }), _jsxs(FormControl, Object.assign({ required: true, size: "small", sx: {
                                                                        marginTop: 2,
                                                                    } }, { children: [_jsx(InputLabel, { children: t("2fa.token") }), _jsx(OutlinedInput, { size: "small", label: t("2fa.token"), value: authenticationCode, onChange: event => setAuthenticationCode(event.target.value) })] })), _jsx(Box, Object.assign({ sx: {
                                                                        marginTop: 2,
                                                                    } }, { children: _jsx(LoadingButton, Object.assign({ type: "button", variant: "contained", color: "primary", size: "small", disabled: authenticationCode.length !==
                                                                            6, disableElevation: true, loading: isAuthenticationLoading, onClick: handleAuthenticationConfirm, loadingIndicator: _jsx(CircularProgress, { color: "inherit", size: 16 }) }, { children: t("confirm") })) }))] })) : (_jsxs(Fragment, { children: [_jsx(Alert, Object.assign({ severity: "warning" }, { children: t("2fa.disabled") })), _jsx(Typography, Object.assign({ variant: "body2", color: "textSecondary", sx: {
                                                                        marginY: 2,
                                                                    } }, { children: t("2fa.explain") })), _jsx(Button, Object.assign({ color: "primary", variant: "contained", size: "small", disableElevation: true, onClick: () => setIsAuthenticationOpened(true) }, { children: t("2fa.enable") }))] })) })) })) })) }) }))))] })) })))] })), _jsxs(Dialog, Object.assign({ open: isAuthenticationOpened, onClose: handleAuthenticationClose }, { children: [_jsx(DialogTitle, { children: t("users.user-attributes.confirm-password") }), _jsx(DialogContent, { children: _jsxs(FormControl, Object.assign({ fullWidth: true }, { children: [_jsx(InputLabel, { children: t("users.user-attributes.password") + " *" }), _jsx(OutlinedInput, { type: showAuthenticationPassword ? "text" : "password", label: t("users.user-attributes.password") + " *", value: authenticationPassword, onChange: event => setAuthenticationPassword(event.target.value), endAdornment: _jsx(InputAdornment, Object.assign({ position: "end" }, { children: _jsx(IconButton, Object.assign({ onClick: () => setShowAuthenticationPassword(!showAuthenticationPassword), edge: "end", size: "large" }, { children: showAuthenticationPassword ? (_jsx(VisibilityIcon, {})) : (_jsx(VisibilityIconOff, {})) })) })) }), _jsx(FormHelperText, { children: t("2fa.confirm-password") })] })) }), _jsxs(DialogActions, { children: [_jsx(Button, Object.assign({ color: "inherit", onClick: handleAuthenticationClose }, { children: t("cancel") })), _jsx(LoadingButton, Object.assign({ type: "button", variant: "contained", color: "primary", size: "small", disabled: authenticationPassword === "", disableElevation: true, loading: isAuthenticationLoading, onClick: handleAuthenticationPassword, loadingIndicator: _jsx(CircularProgress, { color: "inherit", size: 16 }) }, { children: t("confirm") }))] })] }))] }));
}
